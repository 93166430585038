@import "@mm-ui/components/resources/scss/metro/01_settings/ui";
@import "@mm-ui/components/resources/scss/metro/02_tools/functions";
@import "@mm-ui/components/resources/scss/metro/01_settings/colorsystem";

markdown {
  line-height: 1.57;
  color: color(grey);

  a {
    img {
      border: 0;
      max-width: 100%;
    }
  }

  .m p,
  .m-p {
    font-weight: normal;
    font-size: px2rem(14);
    line-height: 1.57;
    color: color(grey);
  }

  h2, h3, h4, h5, h6 {
    margin: 0.5rem 0;
  }

  br+img {
    padding: px2em(12) 0;
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    list-style-position: inside;
    padding-left: 0;
  }

  ul {
    @extend ol;
    list-style-type: square;
  }

  li {
    line-height: px2rem(16);
    margin: px2rem(12) 0;
    a {
      display: inline;
    }
  }

  li > p:first-child {
    display: inline;
  }

  li > ul {
    padding-left: px2em(16);
    list-style-type: circle;
  }

  li > ol {
    padding-left: px2em(16);
  }

  pre {
    background: color-tint(grey, 95);
    line-height: px2rem(16);
    overflow: auto;
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: px2rem(16) 0;
    border-radius: px2rem(4);
    padding: px2rem(10);
  }

  code:not([class*=language-]) {
    background: color-tint(grey, 95);
    border-radius: px2rem(2);
    color: color(metro-blue);
    padding: px2rem(2) px2rem(4);
  }

  pre > code:not([class*=language-]) {
    color: color(grey);
  }

  table {
    min-width: 50%;
    display: table;
    border-spacing: 0;
    border-collapse: collapse;
    margin-bottom: px2rem(16);
  }

  thead {
    border-bottom: px2rem(1) solid color(metro-blue);
    color: color(metro-blue);
    padding: px2rem(8) px2rem(20);
    font-size: px2rem(16);
    font-weight: 900;
  }

  th {
    text-align: left;
    padding: px2rem(8) px2rem(12);
  }

  th[align=center], td[align=center] {
    text-align: center;
  }

  th[align=right], td[align=right] {
    text-align: right;
  }

  th[align=left], td[align=left] {
    text-align: left;
  }

  td {
    padding: px2rem(10) px2rem(12);
    border-bottom: 1px solid color-tint(grey, 80);
  }

  pre > code {
    max-width: 500px;
  }

  .m-info-box {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: px2rem(1) 0;
    border-radius: px2rem(4);
    padding: px2rem(9);
  }

  .m-info-box-description {
    p {
      font-size: px2rem(12);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: color(grey);
      padding: 0;
      margin: 0;
    }
  }

  .markdown {
    &__link-external {
      color: color-shade(metro-blue, 40);
      text-decoration: underline;
      text-decoration-color: color(blue);
      text-decoration-width: px2rem(1);
      font-weight: bold;
      &:hover, &:active, &:visited {
        text-decoration: underline;
        text-decoration-color: color(blue);
        text-decoration-width: px2rem(1);
      }
    }
    &__link-icon {
      width: px2rem(16);
      height: px2rem(16);
    }
  }
}

.m markdown h1 {
  font-weight: bold;
}
