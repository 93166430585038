@media print {
  .m-sideGrid-sideNavigation {
    display: none;
  }

  .m-host-verticalHeader {
    display: none;
  }

  .m-page-sideBar {
    padding-left: 0;
  }

  .m-navigationHeaderBreadcrumb {
    display: none;
  }

  .m-info-box, .m-tag {
    border: solid 1px grey;
  }

  .m-panel {
    height: auto !important;
  }

  .hlp-page__next {
    display: none;
  }

  pre {
    border: solid 1px grey;
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    code {
      width: 100%;
    }
  }
}
