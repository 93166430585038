body {
  min-width: 1024px;
}

.hlp {
  img {
    border-radius: 0.25rem;
    border: 1px solid #ddd;
    width: 100%;
  }

  &-page-bar {
    margin-bottom: 1rem;
  }

  &-badge {
    line-height: 0.75rem;
    padding: 0.0625rem 0.25em;
    font-size: 0.6875rem;
    display: inline-flex;
    align-items: center;
    height: 1rem;
    border-radius: 2px;
    border: solid 1px;
    cursor: default;
    vertical-align: super;
  }

  &-no-data-box {
    height: 10rem;
  }
}

h4 {
  .hlp-badge {
    vertical-align: text-bottom;
  }
}
